<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice/lesson' }">课程</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="title flex justify-between">
        <div class="left">
          <div class="title-s">{{ obj.courseName }}</div>
          <!-- <div class="detail flex">
            <span>2021/03/24</span>
            <span>共30页</span>
            <span>345kb</span>
            <span><i class="el-icon-view"></i>8765</span>
          </div> -->
        </div>
        <div class="right flex">
          <!-- 点赞 -->
          <div class="action_groupItem flex" @click="setFavorite(obj)">
            <div class="action_icon" v-if="!obj.isUp">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-dianzan"></use>
              </svg>
            </div>
            <div class="action_icon" v-else>
              <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                <use xlink:href="#icon-dianzan_active"></use>
              </svg>
            </div>
            <div class="action_text">{{ obj.isUp ? "已点赞" : "点赞" }}</div>
          </div>
          <!-- 分享 -->
          <div class="action_groupItem flex" @click="setShare">
            <div class="action_icon">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-fenxiang"></use>
              </svg>
            </div>
            <div class="action_text">分享</div>
          </div>
          <!-- 收藏 -->
          <div class="action_groupItem flex" @click="setCollect(obj)">
            <div class="action_icon" v-if="!obj.isCollent ">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shoucang"></use>
              </svg>
            </div>
            <div class="action_icon" v-else>
              <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                <use xlink:href="#icon-shoucang_active"></use>
              </svg>
            </div>
            <div class="action_text">{{ obj.isCollent ? "已收藏" : "收藏" }}</div>
          </div>
        </div>
      </div>

      <div class="content" v-if="type == 'pdf'">
        <iframe class="iframe" :src="obj.content" frameborder="0"></iframe>
      </div>
      <div class="content" v-if="type != 'pdf'">
        <iframe class="iframe" :src="'http://view.officeapps.live.com/op/view.aspx?src=' + obj.content"
          frameborder="0"></iframe>
      </div>

      <Comments />
    </div>
  </transition>
</template>

<script>
import Comments from "@/views/practice/lesson/components/Comments";
export default {
  components: {
    Comments,
  },
  data () {
    return {
      courseCode: "",
      obj: {},
      content: "",
      type: "",
      memberCode: ''
    };
  },
  methods: {

    setFavorite (obj) {
      //点赞
      if (!obj.isUp) {
        this._handleCourseUp(obj);
      } else {
        this.$message({
          message: "已经点赞过了哦",
          type: "info",
          offset:'400',
        });
      }
    },


    _handleCourseUp (obj) {
      //点赞
      this.$api.lesson
        .handleCourseUp({
          courseCode: this.courseCode,
          memberCode: this.memberCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            obj.isUp = true
          }
        });
    },

    setCollect (obj) {
      //收藏
      this.$api.lesson.addCollect({
        memberCode: this.memberCode,
        courseCode: obj.courseCode
      }).then(res => {
        if (res.data.code === 200) {
          obj.isCollent = !obj.isCollent
        }
      })
    },




    setShare () {
      //分享

      if (!this.obj.content) {
        // this.$message.error('该内容暂不可分享')
        this.$message({
            message: "该内容暂不可分享!",
            type: "error",
            offset:'400'
          });
        return
      }

      //创建input标签
      let input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = 'http://view.officeapps.live.com/op/view.aspx?src=' + this.obj.content;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //移除input标签
      document.body.removeChild(input)
      // this.$message.success('图文地址复制成功')
        this.$message({
            message: "图文地址复制成功!",
            type: "success",
            offset:'400'
          });
    },

    queryOneCourse () {
      //获取课程
      this.$api.lesson
        .queryOneCourse({
          courseCode: this.courseCode,
          memberCode: this.memberCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.obj = res.data.data;
            if (this.obj.content.indexOf("PDF") != -1 || this.obj.content.indexOf("pdf") != -1) {
              this.type = "pdf";
            } else if (
              this.obj.content.indexOf("doc") != -1 ||
              this.obj.content.indexOf("docx") != -1 ||
              this.obj.content.indexOf("DOC") != -1 ||
              this.obj.content.indexOf("DOCX") != -1
            ) {
              this.type = "word";
            } else if (
              this.obj.content.indexOf("xls") != -1 ||
              this.obj.content.indexOf("xlsx") != -1 ||
              this.obj.content.indexOf("XLS") != -1 ||
              this.obj.content.indexOf("XLSX") != -1
            ) {
              this.type = "excel";
            } else if (
              this.obj.content.indexOf("ppt") != -1 ||
              this.obj.content.indexOf("pptx") != -1 ||
              this.obj.content.indexOf("PPT") != -1 ||
              this.obj.content.indexOf("PPTX") != -1
            ) {
              this.type = "ppt";
            }
          }
        });
    },
  },
  created () {
    this.courseCode = this.$route.query.courseCode;
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.queryOneCourse();
  },
};
</script>

<style lang="less" scoped>
.title {
  margin: 30px 0;
  .title-s {
    font-size: 20px;
  }
  .detail {
    color: #7f7f7f;
    span {
      margin-top: 8px;
      margin-right: 20px;
    }
  }
  .right {
    span {
      font-size: 12px;
      margin-left: 20px;
    }
  }
}
.action_groupItem {
  margin-left: 20px;
  cursor: pointer;
}
.action_text {
  margin-left: 5px;
}
.content {
  background: #f4f4f4;
  height: 900px;
  .iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
